<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="4" md="12">
        <StatisticCardHorizontal
          icon="UserIcon"
          :statistic="this.data.countMember"
          :statistic-title="$t('general.totalMember')"
          color="info"
        />
      </b-col>
      <b-col lg="4" md="12">
        <StatisticCardHorizontal
          icon="UsersIcon"
          :statistic="this.data.countPlayer"
          :statistic-title="$t('general.totalPlayer')"
          color="primary"
        />
      </b-col>
      <b-col lg="4" md="12">
        <StatisticCardHorizontal
          icon="TrendingUpIcon"
          :statistic="this.data.countFight"
          :statistic-title="$t('general.totalRound')"
          color="success"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import Repository from "@/repositories/RepositoryFactory";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: { BRow, BCol, StatisticCardHorizontal },
  data() {
    return {
      data: {
        countMember: 0,
        countPlayer: 0,
        countFight: 0,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await DashboardRepository.index({});

      if (response.data.code == 200) {
        this.data = {
          ...response.data.data,
        }
      }
    },
  },
};
</script>

<style>
</style>
